.aboutrepairs_block
  padding-top: 120px
  padding-bottom: 100px
  background: #191919

.aboutr_content
  display: flex
  flex-direction: row
  justify-content: space-between
  gap: 89px
  align-items: center

.aboutr_content_left
  max-width: 791px

.aboutr_content_text
  color: #A9A9A9
  font-family: Open Sauce One, sans-serif
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: 25px
  margin-bottom: 32px

.aboutr_content_right
  background-image: url("../../../Assets/Images/AboutRepairs/AboutRepairsImg.webp")
  background-size: cover
  background-repeat: no-repeat
  width: 100%
  height: 470px

.aboutr_checks_block
  display: flex
  flex-direction: column
  gap: 12px

.aboutr_check_block
  display: flex
  align-items: center
  gap: 12px

.aboutr_check_text
  color: #A9A9A9
  font-family: Open Sauce One, sans-serif
  font-size: 14px
  font-style: normal
  font-weight: 600
  line-height: 128%
  text-transform: uppercase
  margin: 0

@media (max-width: 1820px)

  .aboutr_content
    gap: 50px

  .aboutr_content_left
    max-width: 520px

  .aboutr_content_right
    height: 318px

@media (max-width: 1200px)

  .aboutr_content
    gap: 32px
    display: flex
    flex-direction: column-reverse

  .aboutr_content_left
    max-width: 100%

  .aboutr_content_right
    height: 470px
    background-size: cover


@media (max-width: 720px)

  .aboutrepairs_block
    padding-top: 60px
    padding-bottom: 60px

  .aboutr_content
    gap: 32px
    display: flex
    flex-direction: column-reverse

  .aboutr_content_left
    max-width: 100%

  .aboutr_content_right
    background-image: url("../../../Assets/Images/AboutRepairs/AboutRepairsMob.webp")
    height: 350px
    background-size: cover

  .aboutr_content_text
    font-size: 14px
    line-height: 25px

  .aboutr_check_text
    font-size: 12px

