.vinyl
  background: #191919
  padding: 80px 0
  &_wrap
    padding: 40px 0 0 0
    display: flex
    justify-content: space-between
@media(max-width: 1200px)
  .vinyl
    &_wrap
      flex-direction: column
      align-items: center
@media(max-width: 720px)
      