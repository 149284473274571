.nav
  padding: 47px 0
  background: #111
  &_wrap
    display: flex
    align-items: center
    justify-content: space-between
    width: 600px

  &_item
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    text-decoration: none
    cursor: pointer
    &__img
      width: 56px
      margin-bottom: 7px
    &__title
      color: #FFF
      font-family: "Open Sauce One", sans-serif
      font-size: 18px
      font-style: normal
      font-weight: 500
      line-height: normal
@media (max-width: 720px)
  .nav
    padding: 40px 20px
    &_wrap
      width: 100%
      flex-wrap: wrap
      height: 300px
    &_item
      flex-basis: 50%
      &__img
        margin-bottom: 7px
      &__title
        color: #FFF
        font-family: "Open Sauce One", sans-serif
        font-size: 18px
        font-style: normal
        font-weight: 500
        line-height: normal