.europaenwin_block
  padding-top: 100px
  padding-bottom: 100px
  background: #111

.europaenwin_content
  display: flex
  flex-direction: row-reverse
  justify-content: space-between
  gap: 89px
  align-items: center

.europaenwin_content_left
  max-width: 750px

.europaenwin_content_text
  color: #A9A9A9
  font-family: Open Sauce One, sans-serif
  font-size: 18px
  font-style: normal
  font-weight: 400
  line-height: 25px
  margin-bottom: 0

.europaenwin_content_right
  background-image: url("../../../Assets/Images/AboutWindows/europeanwin_bg.webp")
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  width: 100%
  height: 500px

.europaenwin_title_block
  display: flex
  align-items: flex-end
  gap: 32px
  margin-bottom: 40px

.europaenwin_title
  color: #FFF
  font-family: Open Sauce One, sans-serif
  font-size: 44px
  font-style: normal
  font-weight: 500
  line-height: normal
  margin: 0

.europaenwin_title_line
  height: 2px
  flex: 1 0 0
  border-radius: 12px
  background: #FECB00

@media (max-width: 1820px)

  .europaenwin_content
    gap: 100px

  .europaenwin_content_left
    max-width: 520px

  .europaenwin_content_right
    height: 349px


@media (max-width: 1200px)

  .europaenwin_content
    gap: 40px
    display: flex
    flex-direction: column-reverse

  .europaenwin_content_left
    max-width: 100%

  .europaenwin_content_right
    height: 414px
    background-size: cover

  .europaenwin_title_line
    display: none

  .europaenwin_title_block
    justify-content: center


@media (max-width: 720px)

  .europaenwin_block
    padding-top: 60px
    padding-bottom: 60px

  .europaenwin_content
    gap: 40px
    display: flex
    flex-direction: column-reverse

  .europaenwin_content_left
    max-width: 100%

  .europaenwin_content_right
    background-image: url("../../../Assets/Images/AboutWindows/europeanwin_bg.webp")
    height: 185px
    background-size: cover

  .europaenwin_content_text
    font-size: 14px
    line-height: 25px
    margin-bottom: 0

  .europaenwin_title_block
    justify-content: center

  .europaenwin_title
    font-size: 24px
    text-align: center

  .europaenwin_title_line
    display: none

