.footer_block
  padding: 80px 0 48px 0
  background: #111

.footer_logo
  max-width: 346px
  height: auto
  margin-bottom: 52px

.footer_main_block
  display: flex
  flex-direction: row
  gap: 32px

.footer_info_block
  display: flex
  flex-direction: row
  gap: 32px
  align-items: flex-end

.footer_line_block
  display: flex
  flex-direction: column
  gap: 21px
  min-width: 422px

.footer_info_line
  display: flex
  flex-direction: row
  align-items: center
  gap: 16px

.footer_info_icon
  width: 24px
  height: auto

.footer_info_text
  color: #FFF
  font-family: Open Sauce One, sans-serif
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: 150%
  margin: 0
  transition: transform 0.3s ease
  text-decoration: none
  cursor: pointer

.footer_info_text:hover
  transform: scale(1.1)
  z-index: 1

.footer_soc_block
  display: flex
  flex-direction: row
  align-items: center
  gap: 24px

.footer_soc_icons_block
  display: flex
  flex-direction: row
  gap: 8px
  align-items: center

.footer_soc_text
  color: #A9A9A9
  font-family: Open Sauce One, sans-serif
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: 150%
  margin: 0
 
.footer_soc_icon
  width: 40px
  height: auto


.footer_link_block
  display: flex
  flex-direction: column
  gap: 4px
  min-width: 358px
  //margin-top: 52px

.footer_block_link_text
  display: flex
  height: 32px
  padding: 4px 16px
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 3px


  
.footer_link_text
  color: #FFF
  font-family: Open Sauce One, sans-serif
  font-size: 16px
  font-style: normal
  font-weight: 500
  line-height: normal
  margin: 0
  transition: transform 0.3s ease
  cursor: pointer
  text-decoration: none

.footer_link_text:hover
  transform: scale(1.1)
  z-index: 1

.contact_map
  width: 720px
  height: 319px
  border-radius: 0

.footer_copyright
  flex-direction: row
  justify-content: center
  display: none

.footer_copyright_text
  display: flex
  color: #A9A9A9
  font-family: Open Sauce One, sans-serif
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: 24px
  letter-spacing: 0.2px
  padding-right: 8px
  margin: 0

.footer_copyright_icon
  margin-left: 8px
  margin-right: 4px

.footer_copyright_text_span
  color: #442CE9
  font-family: Montserrat, sans-serif
  font-size: 11.667px
  font-style: normal
  font-weight: 600
  line-height: 20px
  letter-spacing: 0.167px
  margin: 0

@media (max-width: 1820px)

  .footer_logo
    max-width: 300px
    height: auto
    margin-bottom: 52px

  .footer_line_block
    min-width: 277px

  .footer_link_block
    min-width: 213px

  .contact_map
    width: 554px
    height: 319px
    border-radius: 0

@media (max-width: 1200px)
  .footer_main_block
    display: flex
    flex-direction: column
    gap: 32px

  .footer_logo
    max-width: 300px
    height: auto
    margin-bottom: 52px

  .footer_line_block
    min-width: 45%

  .footer_link_block
    min-width: 45%

  .contact_map
    width: 100%
    height: 319px
    border-radius: 0


@media (max-width: 720px)

  .footer_info_block
    display: flex
    flex-direction: column
    gap: 40px
    align-items: flex-end

  .footer_logo
    max-width: 231px
    height: auto
    margin-bottom: 40px

  .footer_line_block
    min-width: 100%

  .footer_link_block
    min-width: 100%

  .footer_block_link_text
    align-items: flex-start

  .contact_map
    width: 100%
    height: 384px
    border-radius: 0

  .footer_copyright
    display: flex
    flex-direction: row
    justify-content: center
    margin-top: 32px

  .footer_copyright_link
    display: flex
    text-decoration: none


