.aboutwin_block
  padding-top: 100px
  padding-bottom: 100px
  background: #191919

.aboutwin_content
  display: flex
  flex-direction: row
  justify-content: space-between
  gap: 89px
  align-items: center

.aboutwin_content_left
  max-width: 750px

.aboutwin_content_text
  color: #A9A9A9
  font-family: Open Sauce One, sans-serif
  font-size: 18px
  font-style: normal
  font-weight: 400
  line-height: 25px
  margin-bottom: 0

.aboutwin_content_right
  background-image: url("../../../Assets/Images/AboutWindows/aboutwindows.webp")
  background-size: cover
  background-repeat: no-repeat
  width: 100%
  height: 500px

.aboutwin_title_block
  display: flex
  align-items: flex-end
  gap: 32px
  margin-bottom: 40px

.aboutwin_title
  color: #FFF
  font-family: Open Sauce One, sans-serif
  font-size: 44px
  font-style: normal
  font-weight: 500
  line-height: normal
  margin: 0

.aboutwin_title_line
  height: 2px
  flex: 1 0 0
  border-radius: 12px
  background: #FECB00

@media (max-width: 1820px)

  .aboutwin_content
    gap: 50px

  .aboutwin_content_left
    max-width: 520px

  .aboutwin_content_right
    height: 327px


@media (max-width: 1200px)

  .aboutwin_content
    gap: 32px
    display: flex
    flex-direction: column

  .aboutwin_content_left
    max-width: 100%

  .aboutwin_content_right
    height: 413px
    background-size: cover


@media (max-width: 720px)

  .aboutwin_block
    padding-top: 60px
    padding-bottom: 60px

  .aboutwin_content
    gap: 40px
    display: flex
    flex-direction: column

  .aboutwin_content_left
    max-width: 100%

  .aboutwin_content_right
    background-image: url("../../../Assets/Images/AboutWindows/aboutwin_mobile.webp")
    height: 183px
    background-size: cover

  .aboutwin_content_text
    font-size: 14px
    line-height: 25px
    margin-bottom: 0

  .aboutwin_title_block
    justify-content: center

  .aboutwin_title
    font-size: 24px
    text-align: center

  .aboutwin_title_line
    display: none

