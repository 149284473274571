.weather
  padding: 80px 0
  background: #000
  &_order
    order: 1
  &_wrap
    margin-top: 30px
    display: flex
    gap: 48px
@media(max-width: 1200px)
  .weather
    &_wrap
      flex-direction: column