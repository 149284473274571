@mixin title
  display: flex
  align-items: flex-end
  gap: 32px
  &_line
    all: unset
    height: 2px
    width: 100%
    flex: 1 0 0
    opacity: 1
    border-radius: 12px
    background: #FECB00
    margin: 0
  &_title
    color: #FFF
    font-family: "Open Sauce One", sans-serif
    font-size: 44px
    font-style: normal
    font-weight: 500
    line-height: normal
    margin: 0
