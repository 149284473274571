@import "../../../Constants/variables.module"

.gallery
  padding-top: 80px
  padding-bottom: 80px
  background-color: #000

.titles
  @include title


.box
  margin-top: 40px
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-template-rows: repeat(2, 1fr)
  gap: 20px
  &_11
    grid-column: 1
    grid-row: 1
  &_12
    grid-column: 2
    grid-row: 1
  &_13
    grid-column: 1 / 3
    grid-row: 2
  &_21
    grid-column: 3
    grid-row: 1
  &_22
    grid-column: 4
    grid-row: 1
  &_23
    grid-column: 3 / 5
    grid-row: 2


@media (max-width: 720px)
  .gallery
    padding-top: 60px
    padding-bottom: 60px

  .box
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: repeat(4, 1fr)
    gap: 20px
    &_11
      grid-column: 1
      grid-row: 1
    &_12
      grid-column: 2
      grid-row: 1
    &_13
      grid-column: 1 / 3
      grid-row: 2
    &_21
      grid-column: 1
      grid-row: 3
    &_22
      grid-column: 2
      grid-row: 3
    &_23
      grid-column: 1 / 3
      grid-row: 4
