.main_bg {
  position: relative;
}

.main_bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(359deg, rgba(0, 0, 0, 0) -12.18%, rgba(0, 0, 0, 0.65) 54.3%, #000 116.98%);
  z-index: 1;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: absolute;
  top: 30%;
  right: 50%;
  transform: translateX(50%);
  z-index: 2;
}

.title {
  color: #FFF;
  font-family: "Open Sauce One", sans-serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sub {
  color: #FFF;
  text-align: center;
  font-family: "Open Sauce One", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 40px;
}

.button {
  display: flex;
  width: 240px;
  height: 48px;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: none;
  border-radius: 12px;
  background: #FECB00;
  box-shadow: 0 10px 15px -3px rgba(42, 38, 1, 0.4), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  color: #111;
  font-family: "Open Sauce One", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@media (max-width: 1200px) {
  .title {
    font-size: 40px;
  }
  .sub {
    font-size: 14px;
  }
  .button {
    font-size: 16px;
  }
}
@media (max-width: 720px) {
  .mainContainer {
    align-items: flex-start;
  }
  .sub {
    text-align: start;
  }
}

