.background {
  background-image: url("../../../Assets/Images/LandingPage/WhyUs/WhyUsWeb.webp");
  background-size: cover;
  background-position: center;
  padding: 100px 0;
}

.whyUs {
  display: flex;
  justify-content: center;
  gap: 60px;
}

.image {
  background-image: url("../../../Assets/Images/LandingPage/WhyUs/WhyUsImg.webp");
  background-size: cover;
  background-position: center;
  width: 616px;
  height: 495px;
}

.title {
  color: #FFF;
  font-family: "Open Sauce One", sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
}

.description {
  width: 792px;
  color: #A9A9A9;
  font-family: "Open Sauce One", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 26px;
}

.button {
  color: #FECB00;
  font-family: "Open Sauce One", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 0 !important;
}

@media (max-width: 1820px) {
  .description {
    width: 464px;
  }
}
@media (max-width: 1200px) {
  .whyUs {
    gap: 20px;
  }
  .image {
    width: 347px;
    background-image: url("../../../Assets/Images/LandingPage/WhyUs/WhyUsImgTablet.webp");
  }
  .description {
    width: 353px;
  }
}
@media (max-width: 720px) {
  .whyUs {
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .title {
    font-size: 24px;
    margin-bottom: 16px;
  }
  .description {
    width: 90%;
  }
  .image {
    width: 90%;
    height: 273px;
    background-image: url("../../../Assets/Images/LandingPage/WhyUs/WhyUsImg.webp");
  }
  .button {
    position: absolute;
    bottom: -50px;
    right: 50%;
    transform: translateX(50%);
  }
  .button:focus {
    transform: translateX(50%);
  }
}

