.gallery {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #000;
}

.titles {
  display: flex;
  align-items: flex-end;
  gap: 32px;
}
.titles_line {
  all: unset;
  height: 2px;
  width: 100%;
  flex: 1 0 0;
  opacity: 1;
  border-radius: 12px;
  background: #FECB00;
  margin: 0;
}
.titles_title {
  color: #FFF;
  font-family: "Open Sauce One", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.box {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
}
.box_11 {
  grid-column: 1;
  grid-row: 1;
}
.box_12 {
  grid-column: 2;
  grid-row: 1;
}
.box_13 {
  grid-column: 1/3;
  grid-row: 2;
}
.box_21 {
  grid-column: 3;
  grid-row: 1;
}
.box_22 {
  grid-column: 4;
  grid-row: 1;
}
.box_23 {
  grid-column: 3/5;
  grid-row: 2;
}

@media (max-width: 720px) {
  .gallery {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .box {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 20px;
  }
  .box_11 {
    grid-column: 1;
    grid-row: 1;
  }
  .box_12 {
    grid-column: 2;
    grid-row: 1;
  }
  .box_13 {
    grid-column: 1/3;
    grid-row: 2;
  }
  .box_21 {
    grid-column: 1;
    grid-row: 3;
  }
  .box_22 {
    grid-column: 2;
    grid-row: 3;
  }
  .box_23 {
    grid-column: 1/3;
    grid-row: 4;
  }
}

