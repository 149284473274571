.myModal
  min-width: 650px
  padding: 32px
  width: 100%
  border-radius: 24px
  background: #FFF
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.10)
  z-index: 3000

.my-1
  margin-top: .35rem !important


.form
  display: flex
  flex-direction: column
  gap: 32px
  &_top
    display: flex
    flex-direction: column
    gap: 8px
    &__first
      display: flex
      align-items: center
      justify-content: space-between
      &_title
        color: #19191C
        font-family: "TT Norms", sans-serif
        font-size: 32px
        font-style: normal
        font-weight: 600
        line-height: 38px
        margin-bottom: 0
      &_img
        width: 25.6px
        height: 25.6px
        cursor: pointer
        border-radius: 50%
        &:hover
          background: #F2F2F2
    &__second
      color: #7E818B
      font-family: "TT Norms", sans-serif
      font-size: 20px
      font-style: normal
      font-weight: 450
      line-height: 24px
      margin-bottom: 0
  &_bottom
    display: flex
    flex-direction: column
    gap: 18px
    &__box
      width: 100%
      &_lbl
        color: #000
        font-family: "TT Norms", sans-serif
        font-size: 16px
        font-style: normal
        font-weight: 450
        line-height: 120%
        margin-bottom: 6px
        & span
          color: #515C6D
          font-family: "TT Norms", sans-serif
          font-size: 14px
          font-style: normal
          font-weight: 450
          line-height: 120%
      &_wrap
        border-radius: 12px
        border: 1px solid #CACDD5
        background: #FFF
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05)
        padding: 10px 12px
        display: flex
        align-items: center
        gap: 8px
        height: 44px
        &__img
          width: 20px
          height: 20px
        &__inp
          width: 100%
          outline: none
          border: none
          border-radius: 6px
          padding-left: 3px
          &::placeholder
            color: #AAADB9
            font-family: "TT Norms", sans-serif
            font-size: 14px
            font-style: normal
            font-weight: 400
            line-height: 24px
        &__telInp
          width: 100%
          height: 24px
          & > :nth-child(1)
            & > :nth-child(1)
              border: none!important
              height: 24px!important
          & > :nth-child(2)
            height: 24px!important
            width: 100%
            border: none!important
            color: #000!important
            font-family: "TT Norms", sans-serif!important
            font-size: 14px!important
            font-style: normal!important
            font-weight: 400!important
            line-height: 24px!important
            border-radius: 6px!important
      &_checkBoxWrap
        display: flex
        align-items: center
        & > label
          padding: 0 0 0 4px
          margin: 0
        &__lbl
          color: #7E818B
          font-feature-settings: 'clig' off, 'liga' off
          font-family: 'Gilroy', sans-serif
          font-size: 14px
          font-style: normal
          font-weight: 500
          line-height: 18px
          margin-left: 4px
          cursor: pointer
  &_btns
    display: flex
    align-items: center
    justify-content: center
    gap: 12px
    &__btn
      padding: 12px 48px
      border-radius: 12px
      background: #FECB00
      color: #111
      font-feature-settings: 'clig' off, 'liga' off
      font-family: 'Gilroy', sans-serif
      font-size: 15px
      font-style: normal
      font-weight: 600
      line-height: 17px

.input
  color: #111

.dropdown
  display: flex
  flex-direction: column
  color: #A9A9A9
  border: none
  font-family: "Open Sauce One", sans-serif
  font-size: 16px
  font-style: normal
  font-weight: 500
  line-height: normal

  &_label
    color: #000
    font-family: "Open Sauce One", sans-serif
    font-size: 16px
    font-style: normal
    font-weight: 500
    line-height: normal
    margin-bottom: 6px

  &_select
    color: #FFF
    background: #FFF!important
    border: 1px solid #CACDD5
  &_bg
    color: #A9A9A9
    background: #FFF!important


@media (max-width: 1820px)
  .myModal
    padding: 22px 32px 22px 32px

  .form
    gap: 15px
    &_top
      gap: 4px
      &__first
        &_title
          font-size: 24px
          line-height: 130%
        &_img
          width: 19.2px
          height: 19.2px
      &__second
        font-size: 15px
        line-height: 130%
    &_bottom
      gap: 11px
      &__box
        width: 100%
        &_lbl
          color: #000
          font-family: "TT Norms", sans-serif
          font-size: 16px
          font-style: normal
          font-weight: 450
          line-height: 120%
          margin-bottom: 6px
          & span
            color: #515C6D
            font-family: "TT Norms", sans-serif
            font-size: 14px
            font-style: normal
            font-weight: 450
            line-height: 120%
        &_wrap
          border-radius: 12px
          border: 1px solid #CACDD5
          background: #FFF
          box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05)
          padding: 10px 12px
          display: flex
          align-items: center
          gap: 8px
          height: 34px
          
  .dropdown
    display: flex
    flex-direction: column
    color: #A9A9A9
    border: none
    font-family: "Open Sauce One", sans-serif
    font-size: 16px
    font-style: normal
    font-weight: 500
    line-height: normal

    &_label
      color: #000
      font-family: "Open Sauce One", sans-serif
      font-size: 16px
      font-style: normal
      font-weight: 500
      line-height: normal
      margin-bottom: 6px

    &_select
      color: #FFF
      background: #FFF!important
      border: 1px solid #CACDD5
      height: 34px
    &_bg
      color: #A9A9A9
      background: #FFF!important



@media (max-width: 720px)
  .myModal
    min-width: 300px
    max-width: 90%
    padding: 20px
    width: 90%
    border-radius: 16px

  .form
    gap: 20px
    &_top
      gap: 4px
      &__first
        &_title
          font-size: 24px
          line-height: 130%
        &_img
          width: 19.2px
          height: 19.2px
      &__second
        font-size: 15px
        line-height: 130%
    &_bottom
      gap: 18px
      &__box
        &_wrap
          gap: 2px
        &_checkBoxWrap
          &__lbl
            font-size: 12px
            line-height: 130%
    &_btns
      display: flex
      flex-direction: column
      align-items: stretch
      justify-content: center
      gap: 8px
      &__btn
        padding: 12px 48px
        justify-content: center
