.mainBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #000;
  text-align: center;
  height: 95vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  color: var(--White, #FFF);
  font-family: Roboto, sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.title_mod {
  color: var(--Brown, #CFB7A1);
}

.subtitle {
  color: var(--Light-Grey, #D0D5DD);
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.button {
  display: flex;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: none;
  border-radius: 12px;
  background: #FECB00;
  box-shadow: 0 10px 15px -3px rgba(42, 38, 1, 0.4), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  color: #111;
  font-family: "Open Sauce One", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.button:hover {
  opacity: 0.7;
}

@media (max-width: 720px) {
  .title {
    font-size: 42px;
  }
  .subtitle {
    font-size: 18px;
  }
}

