.card
  width: 895px
  &_title
    color: #A9A9A9
    font-family: "Open Sauce One", sans-serif
    font-size: 16px
    font-style: normal
    font-weight: 400
    line-height: 25px
    margin-bottom: 32px
  &_list
    display: flex
    flex-direction: column
    padding: 0
    margin-bottom: 65px
    &__item
      display: flex
      color: #A9A9A9
      font-family: "Open Sauce One", sans-serif
      font-size: 14px
      font-style: normal
      font-weight: 600
      line-height: 128%
      text-transform: uppercase
      margin-bottom: 12px
    &__img
      margin-right: 12px
  &_desc
    display: flex
    align-items: center
    color: #FECB00
    font-family: "Open Sauce One", sans-serif
    font-size: 16px
    font-style: normal
    font-weight: 400
    line-height: 25px
    gap: 7px
.logo
  display: flex
  justify-content: space-between
  &_img
    width: 270px
@media(max-width: 1820px)
  .card
    &_title
      width: 574px
      font-size: 14px
    &_wrap
      display: grid
      grid-template-columns: 224px auto
    &_desc
      grid-column: 1 / span 2
      grid-row: 2
    &_list
      margin-bottom: 30px
  .logo
    justify-content: start
    flex-wrap: wrap
    &_img
      width: 170px
      height: 78px
@media(max-width: 1200px)
  .card
    width: auto
    &_title
      margin: auto auto 32px auto
      width: 720px
    &_desc
      display: none
    &_wrap
      display: flex
      align-items: center
      justify-content: center
      gap: 135px
  .logo
    width: 400px
    justify-content: space-between
    &_img
      width: 170px
      height: 78px
@media(max-width: 720px)
  .card
    &_title
      margin: auto auto 32px auto
      width: auto
    &_desc
      display: none
    &_wrap
      flex-direction: column
      align-items: flex-start
      gap: 0
  .logo
    width: 320px
    margin: auto
    &_img
      width: 140px
      height: 63px
