.gallery {
  background-image: url("../../../Assets/Images/LandingPage/Gallery/GalleryWeb.webp");
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 80px;
}

.titles {
  display: flex;
  align-items: flex-end;
  gap: 32px;
}
.titles_line {
  all: unset;
  height: 2px;
  width: 100%;
  flex: 1 0 0;
  opacity: 1;
  border-radius: 12px;
  background: #FECB00;
  margin: 0;
}
.titles_title {
  color: #FFF;
  font-family: "Open Sauce One", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.carouselWrap {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.carousel {
  position: relative;
  overflow: hidden;
  width: 1520px;
}

.carouselContainer {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carouselSlide {
  min-width: 100%;
  height: 500px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 20px;
}
.carouselSlide_1 {
  grid-column: 1/span 2;
  grid-row: 1/span 1;
  width: 365px;
  height: 500px;
  flex-shrink: 0;
  -webkit-user-drag: none;
}
.carouselSlide_4 {
  grid-column: 4/span 1;
  grid-row: 1/span 2;
  width: 365px;
  height: 500px;
  flex-shrink: 0;
  -webkit-user-drag: none;
}
.carouselSlide_center {
  grid-column: 2/span 2;
  grid-row: 1/span 1;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
}
.carouselSlide_21 {
  grid-column: 1/span 1;
  grid-row: 1/span 1;
  width: 365px;
  height: 240px;
  flex-shrink: 0;
  -webkit-user-drag: none;
}
.carouselSlide_22 {
  grid-column: 1/span 1;
  grid-row: 2/span 1;
  width: 365px;
  height: 240px;
  flex-shrink: 0;
  -webkit-user-drag: none;
}
.carouselSlide_31 {
  grid-column: 2/span 1;
  grid-row: 1/span 1;
  width: 365px;
  height: 240px;
  flex-shrink: 0;
  -webkit-user-drag: none;
}
.carouselSlide_32 {
  grid-column: 2/span 1;
  grid-row: 2/span 1;
  width: 365px;
  height: 240px;
  flex-shrink: 0;
  -webkit-user-drag: none;
}

.carouselArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: inherit;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
  width: 50px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carouselArrow:hover {
  transform: translateY(-50%) scale(1.2);
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.carouselDots {
  text-align: center;
  margin-top: 25px;
}

.carouselDot {
  display: inline-block;
  width: 15px;
  height: 5px;
  margin: 0 5px;
  background-color: #A9A9A9;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.3s, width 0.3s;
}

.activeDot {
  background-color: #FECB00;
  width: 20px;
  height: 6px;
}

@media (max-width: 1820px) {
  .carousel {
    width: 984px;
  }
  .carouselSlide {
    height: 324px;
    column-gap: 13px;
    row-gap: 13px;
  }
  .carouselSlide_1 {
    width: 236px;
    height: 324px;
  }
  .carouselSlide_4 {
    width: 236px;
    height: 324px;
  }
  .carouselSlide_center {
    column-gap: 13px;
    row-gap: 13px;
  }
  .carouselSlide_21 {
    width: 236px;
    height: 155px;
  }
  .carouselSlide_22 {
    width: 236px;
    height: 155px;
  }
  .carouselSlide_31 {
    width: 236px;
    height: 155px;
  }
  .carouselSlide_32 {
    width: 236px;
    height: 155px;
  }
  .carouselArrow {
    width: 40px;
    height: 20px;
  }
  .carouselDots {
    margin-top: 20px;
  }
}
@media (max-width: 1200px) {
  .carousel {
    width: 720px;
  }
  .carouselSlide {
    height: 237px;
    column-gap: 9px;
    row-gap: 9px;
  }
  .carouselSlide_1 {
    width: 173px;
    height: 237px;
  }
  .carouselSlide_4 {
    width: 173px;
    height: 237px;
  }
  .carouselSlide_center {
    column-gap: 9px;
    row-gap: 9px;
  }
  .carouselSlide_21 {
    width: 173px;
    height: 114px;
  }
  .carouselSlide_22 {
    width: 173px;
    height: 114px;
  }
  .carouselSlide_31 {
    width: 173px;
    height: 114px;
  }
  .carouselSlide_32 {
    width: 173px;
    height: 114px;
  }
  .carouselArrow {
    top: auto;
    bottom: 0;
    transform: translateY(0%);
    width: 50px;
    height: 25px;
  }
  .carouselArrow:hover {
    transform: translateY(0%) scale(1.2);
  }
}
@media (max-width: 720px) {
  .carousel {
    width: 320px;
  }
  .carouselSlide {
    grid-template-columns: 1fr;
    height: 214px;
    column-gap: 0;
    row-gap: 0;
  }
  .carouselSlide_1 {
    display: none;
  }
  .carouselSlide_4 {
    display: none;
  }
  .carouselSlide_center {
    column-gap: 10px;
    row-gap: 10px;
  }
  .carouselSlide_21 {
    width: 157px;
    height: 102px;
  }
  .carouselSlide_22 {
    width: 157px;
    height: 102px;
  }
  .carouselSlide_31 {
    width: 157px;
    height: 102px;
  }
  .carouselSlide_32 {
    width: 157px;
    height: 102px;
  }
  .carouselArrow {
    top: auto;
    bottom: 0;
    transform: translateY(0%);
    width: 50px;
    height: 25px;
  }
  .left {
    left: calc(50% - 160px);
  }
  .right {
    right: calc(50% - 160px);
  }
}

