.mobile-buttons
  display: none

@media (max-width: 920px)
  .mobile-buttons
    display: flex
    position: fixed
    bottom: 10px
    left: 0
    width: 100%
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1)
    z-index: 100
    justify-content: center
    background: transparent


  .mobile-button
    padding: 25px 10px
    text-align: center
    border: none
    cursor: pointer

  .book-appointment
    width: 90%
    text-align: center
    font-family: Gilroy, sans-serif
    font-size: 16px
    font-style: normal
    font-weight: 600
    line-height: normal
    background: #FECB00

