.titles {
  display: flex;
  align-items: flex-end;
  gap: 32px;
}
.titles_line {
  all: unset;
  height: 2px;
  width: 760px;
  flex: 1 0 0;
  opacity: 1;
  border-radius: 12px;
  background: #FECB00;
  margin: 0;
}
.titles_title {
  color: #FFF;
  font-family: "Open Sauce One", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.numbers {
  height: 344px;
  background-image: url("../../../Assets/Images/RepairsPage/Numbers/NumbersBg.webp");
  background-size: cover;
  background-position: center;
  padding: 80px 0;
}
.numbers_wrap {
  display: flex;
  gap: 200px;
  justify-content: center;
}
.numbers_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  margin-top: 34px;
}
.numbers_item__number {
  color: #FFF;
  font-family: "Open Sauce One", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
}
.numbers_item__wrap {
  display: flex;
  gap: 8px;
  align-items: center;
}
.numbers_item__img {
  width: 30px;
  height: 30px;
}
.numbers_item__title {
  color: #FFF;
  font-family: "Open Sauce One", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

@media (max-width: 1820px) {
  .numbers_item__number {
    font-size: 40px;
  }
  .numbers_item__title {
    font-size: 18px;
  }
}
@media (max-width: 1200px) {
  .titles_title {
    font-size: 40px;
  }
  .numbers {
    height: 309px;
  }
  .numbers_wrap {
    gap: 40px;
  }
  .numbers_item__number {
    font-size: 32px;
  }
  .numbers_item__title {
    font-size: 16px;
  }
}
@media (max-width: 720px) {
  .titles_title {
    font-size: 22px;
    margin: auto;
  }
  .titles_line {
    display: none;
  }
  .numbers {
    height: 424px;
    padding: 40px 0;
  }
  .numbers_wrap {
    align-items: center;
    flex-direction: column;
    gap: 0;
  }
  .numbers_item {
    width: auto;
    margin-top: 32px;
  }
}

