.cement
  background: #111
  padding: 80px 0
  &_wrap
    padding: 30px 0 0 0
    display: flex
    justify-content: space-between
    box-sizing: border-box
    &__left
      display: flex
      justify-content: space-between
      align-items: center
      width: 940px
      &_card
        width: 300px
        height: 500px
        position: relative
        box-sizing: border-box
        &:hover
          span
            opacity: 1
        &__img
          width: 100%
          height: 100%
        &__hover
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
          background: rgba(50, 50, 50, 0.70)
          color: white
          display: flex
          align-items: center
          justify-content: center
          opacity: 0
          transition: opacity 0.3s ease
          padding: 20px

    &__right
      display: flex
      flex-direction: column
      max-width: 730px
      &_txt
        color: #A9A9A9
        font-family: "Open Sauce One", sans-serif
        font-size: 16px
        font-style: normal
        font-weight: 400
        line-height: 25px
        align-self: stretch
        margin-bottom: 32px
      &_ul
        display: flex
        flex-direction: column
        gap: 12px
        align-items: flex-start
        margin-bottom: 40px
        &__li
          display: flex
          align-items: center
          gap: 12px
          color: #A9A9A9
          font-family: "Open Sauce One", sans-serif
          font-size: 14px
          font-style: normal
          font-weight: 600
          line-height: 128%
          &_img
            width: 16px
            height: 13px
      &_label
        display: flex
        align-items: center
        gap: 7px
        color: #FECB00
        font-family: "Open Sauce One", sans-serif
        font-size: 16px
        font-style: normal
        font-weight: 400
        line-height: 25px
        margin-bottom: 51px
      &_logos
        display: flex
        align-items: center
        &__logo
          width: 240px
          height: 120px


@media (max-width: 1820px)
  .cement
    &_wrap
      &__left
        width: 516px
        &_card
          width: 164px
          height: 404px
      &__right
        max-width: 574px
        &_txt
          font-size: 14px
          margin-bottom: 15px
        &_ul
          margin-bottom: 15px
        &_label
          margin-bottom: 32px
        &_logos
          display: flex
          align-items: center
          justify-content: space-between
          &__logo
            width: 145px
            height: auto


@media (max-width: 1200px)
  .cement
    padding: 60px 0
    &_wrap
      display: flex
      flex-direction: column
      justify-content: flex-start
      align-items: center
      &__left
        display: flex
        justify-content: space-between
        align-items: center
        width: 100%
        margin-bottom: 40px
        &_card
          width: 230px
          height: 383px
      &__right
        position: relative
        display: flex
        flex-direction: column
        max-width: 100%
        &_txt
          color: #A9A9A9
          font-family: "Open Sauce One", sans-serif
          font-size: 16px
          font-style: normal
          font-weight: 400
          line-height: 25px
          align-self: stretch
          margin-bottom: 32px
        &_ul
          display: flex
          flex-direction: column
          gap: 12px
          align-items: flex-start
          margin-bottom: 40px
          &__li
            display: flex
            align-items: center
            gap: 12px
            color: #A9A9A9
            font-family: "Open Sauce One", sans-serif
            font-size: 14px
            font-style: normal
            font-weight: 600
            line-height: 128%
            &_img
              width: 16px
              height: 13px
        &_label
          display: none
        &_logos
          display: flex
          align-items: center
          position: absolute
          right: 0
          bottom: 35px
          &__logo
            width: 120px
            height: auto


@media (max-width: 720px)
  .cement
    padding: 20px 0
    &_wrap
      display: flex
      flex-direction: column
      justify-content: flex-start
      align-items: center
      &__left
        display: flex
        justify-content: space-between
        align-items: center
        width: 100%
        margin-bottom: 40px
        &_card
          width: 102px
          height: 170px
          &__hover
            font-size: 12px
      &__right
        position: relative
        display: flex
        flex-direction: column
        max-width: 100%
        &_txt
          color: #A9A9A9
          font-family: "Open Sauce One", sans-serif
          font-size: 14px
          font-style: normal
          font-weight: 400
          line-height: 25px
          align-self: stretch
          margin-bottom: 32px
        &_ul
          display: flex
          flex-direction: column
          gap: 12px
          align-items: flex-start
          margin-bottom: 40px
          &__li
            display: flex
            align-items: center
            gap: 12px
            color: #A9A9A9
            font-family: "Open Sauce One", sans-serif
            font-size: 12px
            font-style: normal
            font-weight: 600
            line-height: 128%
            &_img
              width: 16px
              height: 13px
        &_label
          display: none
        &_logos
          display: flex
          align-items: center
          position: static
          &__logo
            width: 100px
            height: auto
