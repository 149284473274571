.cement {
  background: #111;
  padding: 80px 0;
}
.cement_wrap {
  padding: 30px 0 0 0;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.cement_wrap__left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 940px;
}
.cement_wrap__left_card {
  width: 300px;
  height: 500px;
  position: relative;
  box-sizing: border-box;
}
.cement_wrap__left_card:hover span {
  opacity: 1;
}
.cement_wrap__left_card__img {
  width: 100%;
  height: 100%;
}
.cement_wrap__left_card__hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 20px;
}
.cement_wrap__right {
  display: flex;
  flex-direction: column;
  max-width: 730px;
}
.cement_wrap__right_txt {
  color: #A9A9A9;
  font-family: "Open Sauce One", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  align-self: stretch;
  margin-bottom: 32px;
}
.cement_wrap__right_ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  margin-bottom: 40px;
}
.cement_wrap__right_ul__li {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #A9A9A9;
  font-family: "Open Sauce One", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 128%;
}
.cement_wrap__right_ul__li_img {
  width: 16px;
  height: 13px;
}
.cement_wrap__right_label {
  display: flex;
  align-items: center;
  gap: 7px;
  color: #FECB00;
  font-family: "Open Sauce One", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 51px;
}
.cement_wrap__right_logos {
  display: flex;
  align-items: center;
}
.cement_wrap__right_logos__logo {
  width: 240px;
  height: 120px;
}

@media (max-width: 1820px) {
  .cement_wrap__left {
    width: 516px;
  }
  .cement_wrap__left_card {
    width: 164px;
    height: 404px;
  }
  .cement_wrap__right {
    max-width: 574px;
  }
  .cement_wrap__right_txt {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .cement_wrap__right_ul {
    margin-bottom: 15px;
  }
  .cement_wrap__right_label {
    margin-bottom: 32px;
  }
  .cement_wrap__right_logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cement_wrap__right_logos__logo {
    width: 145px;
    height: auto;
  }
}
@media (max-width: 1200px) {
  .cement {
    padding: 60px 0;
  }
  .cement_wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .cement_wrap__left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
  }
  .cement_wrap__left_card {
    width: 230px;
    height: 383px;
  }
  .cement_wrap__right {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
  .cement_wrap__right_txt {
    color: #A9A9A9;
    font-family: "Open Sauce One", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    align-self: stretch;
    margin-bottom: 32px;
  }
  .cement_wrap__right_ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    margin-bottom: 40px;
  }
  .cement_wrap__right_ul__li {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #A9A9A9;
    font-family: "Open Sauce One", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 128%;
  }
  .cement_wrap__right_ul__li_img {
    width: 16px;
    height: 13px;
  }
  .cement_wrap__right_label {
    display: none;
  }
  .cement_wrap__right_logos {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 35px;
  }
  .cement_wrap__right_logos__logo {
    width: 120px;
    height: auto;
  }
}
@media (max-width: 720px) {
  .cement {
    padding: 20px 0;
  }
  .cement_wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .cement_wrap__left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
  }
  .cement_wrap__left_card {
    width: 102px;
    height: 170px;
  }
  .cement_wrap__left_card__hover {
    font-size: 12px;
  }
  .cement_wrap__right {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
  .cement_wrap__right_txt {
    color: #A9A9A9;
    font-family: "Open Sauce One", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    align-self: stretch;
    margin-bottom: 32px;
  }
  .cement_wrap__right_ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    margin-bottom: 40px;
  }
  .cement_wrap__right_ul__li {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #A9A9A9;
    font-family: "Open Sauce One", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 128%;
  }
  .cement_wrap__right_ul__li_img {
    width: 16px;
    height: 13px;
  }
  .cement_wrap__right_label {
    display: none;
  }
  .cement_wrap__right_logos {
    display: flex;
    align-items: center;
    position: static;
  }
  .cement_wrap__right_logos__logo {
    width: 100px;
    height: auto;
  }
}

