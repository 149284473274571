.metal
  padding: 80px 0
  background: #111
  &_order
    order: 1
  &_wrap
    margin-top: 30px
    display: flex
    gap: 48px
  &_img
    width: 205px
    height: 120px
@media(max-width: 1820px)
  .metal
    &_img
      width: 151px
      height: 70px
@media(max-width: 1200px)
  .metal
    &_wrap
      flex-direction: column
