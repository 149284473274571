.gallery {
  padding: 100px 0;
  background-image: url("../../../Assets/Images/LandingPage/Gallery/GalleryWeb.webp");
  background-size: cover;
}
.gallery_wrap {
  display: grid;
  grid-template-columns: 588px auto;
  grid-gap: 40px;
}
.gallery_wrap1 {
  display: flex;
  gap: 20px;
}
.gallery_wrap1__main {
  width: 428px;
  height: 428px !important;
}
.gallery_wrap1__second {
  width: 137px;
  height: 137px;
}
.gallery_wrap1__block {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.gallery_wrap2 {
  grid-column-start: 2;
  grid-row-start: 1;
  display: flex;
  flex-direction: column;
}
.gallery_wrap2__title {
  color: #FFF;
  font-family: "Open Sauce One", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  z-index: 10;
}
.gallery_wrap2__title a {
  color: #FFF;
  font-family: "Open Sauce One", sans-serif;
  font-style: normal;
  font-weight: 700;
  z-index: 10;
}
.gallery_wrap2__desc {
  color: #A9A9A9;
  font-family: "Open Sauce One", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
}
.gallery_wrap2__wrapper {
  display: grid;
  grid-template-columns: 387px 214px 387px;
  grid-column-start: 2;
  grid-row-start: 1;
  align-items: flex-end;
  gap: 11px;
}
.gallery_wrap2__item {
  display: flex;
  flex-direction: column;
  gap: 11px;
}
.gallery_wrap2__item_img {
  width: 214px;
  height: 132px;
}
.gallery_wrap2__img1, .gallery_wrap2__img2 {
  width: 387px;
  height: 277px;
}

@media (max-width: 1820px) {
  .gallery_wrap2__wrapper {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-template-columns: 367px 364px 387px;
    gap: 20px;
  }
  .gallery_wrap2__img1, .gallery_wrap2__img2 {
    object-fit: cover;
    height: 470px;
  }
  .gallery_wrap2__item {
    gap: 20px;
  }
  .gallery_wrap2__item_img {
    width: 364px;
    height: 225px;
  }
}
@media (max-width: 1200px) {
  .gallery_wrap {
    grid-template-columns: auto;
    grid-gap: 0;
    row-gap: 44px;
  }
  .gallery_wrap2 {
    grid-column-start: 1;
  }
  .gallery_wrap2__desc {
    font-size: 14px;
  }
  .gallery_wrap2__wrapper {
    grid-template-columns: 335px 364px;
    grid-column-start: 1;
    grid-row-start: 3;
  }
  .gallery_wrap2__img1 {
    width: 335px;
  }
  .gallery_wrap2__img2 {
    display: none;
  }
  .gallery_wrap2__item_img {
    width: 364px;
  }
  .gallery_wrap1 {
    grid-row-start: 2;
  }
  .gallery_wrap1__main {
    height: 523.742px;
    width: 526px;
  }
  .gallery_wrap1__second {
    width: 168.693px;
    height: 167.987px;
  }
}
@media (max-width: 720px) {
  .gallery {
    padding: 50px 0;
  }
  .gallery_wrap {
    grid-template-columns: 320px;
    justify-content: center;
    grid-gap: 0;
    row-gap: 20px;
  }
  .gallery_wrap2 {
    margin: auto;
    grid-column-start: 1;
  }
  .gallery_wrap2__title {
    font-size: 24px;
  }
  .gallery_wrap2__desc {
    line-height: 18px;
  }
  .gallery_wrap2__wrapper {
    grid-template-columns: 320px;
    grid-column-start: 1;
    grid-row-start: 3;
    margin: auto;
  }
  .gallery_wrap2__img1 {
    width: 320px;
    height: 225px;
  }
  .gallery_wrap2__img2 {
    display: none;
  }
  .gallery_wrap2__item {
    display: none;
  }
  .gallery_wrap1 {
    flex-direction: column;
    margin: auto;
  }
  .gallery_wrap1__block {
    flex-direction: row;
  }
  .gallery_wrap1__main {
    height: 320px;
    width: 320px;
  }
  .gallery_wrap1__second {
    width: 101px;
    height: 101px;
  }
}

