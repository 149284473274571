.cases {
  background: #000;
  padding-top: 80px;
  padding-bottom: 80px;
}

.titles {
  display: flex;
  align-items: flex-end;
  gap: 32px;
}
.titles_line {
  all: unset;
  height: 2px;
  width: 100%;
  flex: 1 0 0;
  opacity: 1;
  border-radius: 12px;
  background: #FECB00;
  margin: 0;
}
.titles_title {
  color: #FFF;
  font-family: "Open Sauce One", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.boxes {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 32px;
  padding-left: 30px;
  padding-right: 30px;
  height: 700px;
}
.boxes_btn {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FECB00;
  border: none;
  color: #111;
  text-align: center;
  font-family: "Open Sauce One", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  bottom: 0;
}

.sliderWrapper {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
  cursor: ew-resize;
  user-select: none;
}

.sliderImage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.sliderBefore {
  z-index: 2;
}

.sliderAfter {
  z-index: 1;
}

.sliderHandle {
  position: absolute;
  top: 0;
  z-index: 3;
  width: 2px;
  height: 100%;
  background-color: #FFF;
  cursor: ew-resize;
  transform: translateX(-50%);
}
.sliderHandle::after {
  position: absolute;
  content: "";
  right: 0;
  transform: translateX(50%);
  top: 50%;
  width: 29.455px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 7px;
  background: #FECB00;
}

@media (max-width: 1820px) {
  .cases {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .boxes {
    height: 464px;
  }
  .boxes_btn {
    height: 70px;
    font-size: 20px;
  }
  .sliderWrapper {
    height: 464px;
  }
}
@media (max-width: 1200px) {
  .boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-top: 40px;
    height: auto;
  }
  .boxes_btn {
    font-size: 24px;
  }
  .sliderWrapper {
    height: 700px;
  }
}
@media (max-width: 720px) {
  .cases {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .boxes {
    height: auto;
  }
  .sliderWrapper {
    height: 402px;
  }
}

