.faq_block {
  padding-top: 40px;
  padding-bottom: 100px;
  background: #111;
}

.faq_title_block {
  display: flex;
  align-items: flex-end;
  gap: 32px;
  margin-bottom: 52px;
}

.faq_title {
  color: #FFF;
  font-family: Open Sauce One, sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.faq_title_line {
  height: 2px;
  flex: 1 0 0;
  border-radius: 12px;
  background: #FECB00;
}

.faq_block {
  padding-bottom: 134px;
}

.faq_heading_block {
  margin-bottom: 79px;
}

.faq_heading {
  color: var(--565656, #565656);
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 100px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -7px;
  margin-bottom: 29px;
}

.faq_subheading {
  color: var(--565656, #565656);
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.accordion-button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.accordion {
  border: 1px rgba(208, 213, 221, 0.5) !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.accordion-item {
  margin-bottom: 12px !important;
  border: 1px solid rgba(208, 213, 221, 0.5) !important;
  border-radius: 8px !important;
  background: #111 !important;
}

.accordion-button {
  background: #111 !important;
  color: #FFF !important;
  font-family: Open Sauce One, sans-serif !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding: 24px 32px !important;
  transition: border-radius 0.3s ease;
  border-radius: 8px !important;
  gap: 20px;
}

.accordion-body {
  background-color: #111 !important;
  color: #A9A9A9 !important;
  font-family: Open Sauce One, sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding: 12px 32px 24px 32px !important;
  border-radius: 8px !important;
}

.react-bootstrap-accordion-element:focus,
.react-bootstrap-accordion-element:active {
  outline: none !important;
  border-bottom: none !important;
}

.accordion-button::after {
  content: "" !important;
  display: inline-block !important;
  background-image: url("../../../Assets/Icons/Faq/arrow_more.svg") !important;
  background-repeat: no-repeat !important;
  width: 24px !important;
  height: 12px !important;
  background-size: cover !important;
  transition: transform 0.9s ease, background-image 0.1s ease !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../../Assets/Icons/Faq/arrow_close.svg") !important;
  transform: rotate(180deg) !important;
  background-repeat: no-repeat !important;
  width: 24px !important;
  height: 12px !important;
}

@media (max-width: 1820px) {
  .faq_block {
    padding-top: 40px;
    padding-bottom: 100px;
    background: #111;
  }
  .accordion-button {
    font-size: 18px !important;
    gap: 20px !important;
  }
  .accordion-body {
    font-size: 16px !important;
  }
}
@media (max-width: 720px) {
  .faq_block {
    padding-top: 30px;
    padding-bottom: 60px;
    background: #111;
  }
  .accordion-item {
    margin-bottom: 15px !important;
  }
  .accordion-button {
    font-size: 15px !important;
    padding: 24px !important;
    gap: 10px !important;
  }
  .accordion-body {
    font-size: 14px !important;
    line-height: 128% !important;
    padding: 24px !important;
  }
  .accordion-button::after {
    width: 24px !important;
    height: 12px !important;
  }
  .accordion-button:not(.collapsed)::after {
    width: 24px !important;
    height: 12px !important;
  }
}

