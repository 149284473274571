.partnersdev_block
  padding-top: 100px
  padding-bottom: 100px
  background: #191919

.partnersdev_content
  display: flex
  flex-direction: row-reverse
  justify-content: space-between
  gap: 89px
  align-items: center

.partnersdev_content_left
  max-width: 750px

.partnersdev_content_text
  color: #A9A9A9
  font-family: Open Sauce One, sans-serif
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: 25px
  margin-bottom: 32px

.partnersdev_content_right
  background-image: url("../../../Assets/Images/PartnersDev/partners_dev_bg.webp")
  background-size: cover
  background-repeat: no-repeat
  width: 100%
  height: 500px

.partners_yellow_block
  display: flex
  align-items: center
  padding: var(--spacing-2xl, 20px) var(--spacing-3xl, 24px)
  gap: 12px
  align-self: stretch
  border-radius: var(--layout-radius-rounded-medium, 12px)
  border: 1px solid rgba(254, 203, 0, 0.60)
  background: rgba(254, 203, 0, 0.02)

.partners_yellow_line
  width: 4px
  height: 87px
  border-radius: 33px
  background: #FECB00

.partners_yellow_text_block
  display: flex
  flex-direction: column
  gap: 12px

.partnersdev_text_yellow
  color: #FECB00
  text-align: justify
  font-family: Open Sauce One, sans-serif
  font-size: 16px
  font-style: normal
  font-weight: 500
  line-height: 25px
  margin: 0

.partnersdev_title_block
  display: flex
  align-items: flex-end
  gap: 32px
  margin-bottom: 40px

.partnersdev_title
  color: #FFF
  font-family: Open Sauce One, sans-serif
  font-size: 44px
  font-style: normal
  font-weight: 500
  line-height: normal
  margin: 0

.partnersdev_title_line
  height: 2px
  flex: 1 0 0
  border-radius: 12px
  background: #FECB00

@media (max-width: 1820px)

  .partnersdev_content
    gap: 50px

  .partnersdev_content_left
    max-width: 520px

  .partnersdev_content_right
    height: 409px

  .partners_yellow_line
    height: 150px

  .partnersdev_title_line
    display: none

@media (max-width: 1200px)

  .partnersdev_content
    gap: 32px
    display: flex
    flex-direction: column-reverse

  .partnersdev_content_left
    max-width: 100%

  .partnersdev_content_right
    height: 474px
    background-size: cover

  .partners_yellow_line
    height: 100px

@media (max-width: 720px)

  .partnersdev_block
    padding-top: 100px
    padding-bottom: 100px

  .partnersdev_content
    gap: 32px
    display: flex
    flex-direction: column-reverse

  .partnersdev_content_left
    max-width: 100%

  .partnersdev_content_right
    background-image: url("../../../Assets/Images/PartnersDev/partners_dev_bg_mob.webp")
    height: 470px
    background-size: cover

  .partnersdev_content_text
    font-size: 14px
    line-height: 25px
    margin-bottom: 32px

  .partnersdev_check_text
    font-size: 12px

  .partners_yellow_line
    height: 200px

  .partnersdev_title
    font-size: 24px

