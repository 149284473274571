.wrap {
  position: fixed;
  top: 0;
  background: #111;
  z-index: 1000;
  width: 100%;
  padding: 3px 0;
  backdrop-filter: blur(5px);
}
.wrap_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 11px;
  gap: 31%;
}
.wrap_container_logo {
  width: 56px;
}
.wrap_container_closeBtn {
  margin: 20px;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.wrap_container_closeText {
  color: #D4D4D8;
  font-family: Open Sauce One, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.wrap_container_modalWrap {
  padding: 20px 0 0;
  margin: 56px 0 46px;
  border-radius: 0.35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.wrap_container_modalWrap__link {
  color: #313131;
  font-family: Open Sauce One, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}
.wrap_container_modalWrap__link:hover {
  color: #FECB00;
  text-decoration: underline;
  text-underline-offset: 5px;
}

.container_mobile_btn {
  display: flex;
  height: auto;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  color: #FECB00;
  font-family: Open Sauce One, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: 10px;
  background: black;
  border: none;
}
.container_mobile_btn:hover {
  background: rgba(254, 203, 0, 0.04);
}

@media (min-width: 721px) {
  .wrap {
    display: none;
  }
}

