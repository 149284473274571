.weather {
  padding: 80px 0;
  background: #000;
}
.weather_order {
  order: 1;
}
.weather_wrap {
  margin-top: 30px;
  display: flex;
  gap: 48px;
}

@media (max-width: 1200px) {
  .weather_wrap {
    flex-direction: column;
  }
}

