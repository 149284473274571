.metal {
  padding: 80px 0;
  background: #111;
}
.metal_order {
  order: 1;
}
.metal_wrap {
  margin-top: 30px;
  display: flex;
  gap: 48px;
}
.metal_img {
  width: 205px;
  height: 120px;
}

@media (max-width: 1820px) {
  .metal_img {
    width: 151px;
    height: 70px;
  }
}
@media (max-width: 1200px) {
  .metal_wrap {
    flex-direction: column;
  }
}

