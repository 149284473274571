.nav {
  padding: 47px 0;
  background: #111;
}
.nav_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 600px;
}
.nav_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
}
.nav_item__img {
  width: 56px;
  margin-bottom: 7px;
}
.nav_item__title {
  color: #FFF;
  font-family: "Open Sauce One", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (max-width: 720px) {
  .nav {
    padding: 40px 20px;
  }
  .nav_wrap {
    width: 100%;
    flex-wrap: wrap;
    height: 300px;
  }
  .nav_item {
    flex-basis: 50%;
  }
  .nav_item__img {
    margin-bottom: 7px;
  }
  .nav_item__title {
    color: #FFF;
    font-family: "Open Sauce One", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

