.wrap
  display: flex
  align-items: flex-end
.title
  color: #FFF
  font-family: "Open Sauce One", sans-serif
  font-size: 44px
  font-style: normal
  font-weight: 500
  line-height: normal
  margin: 0 32px 0 0
.line
  height: 2px
  flex: 1 0 0
  border-radius: var(--layout-radius-rounded-medium, 12px)
  background: #FECB00
@media(max-width: 720px)
  .wrap
    align-items: center
    justify-content: center
  .title
    font-size: 24px
  .line
    display: none
