.videoContainer
  position: relative
  width: 100%
  height: 714px
  overflow: hidden

.video
  position: absolute
  object-fit: cover
  width: 100%
  height: 100%


.playPauseButton
  position: absolute
  bottom: 52px
  right: 52px
  background: rgba(50, 50, 50, 0.70)
  border-radius: 50%
  width: 52px
  height: 52px
  display: flex
  align-items: center
  justify-content: center
  z-index: 11

  &:hover
    background-color: rgba(0, 0, 0, 0.8)


@media(max-width: 720px)
  .videoContainer
    height: 666px
  .playPauseButton
    right: 19px
    bottom: 19px
    
