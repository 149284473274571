.main
  background-image: url('../../../Assets/Images/WindowsPage/WindowsMain.webp')
  background-size: cover
  background-position: center
  height: 714px
  display: flex
  justify-content: center
  &_wrap
    display: flex
    flex-direction: column
    align-items: center
    margin: auto
  &_title
    color: #FFF
    font-family: "Open Sauce One", sans-serif
    font-size: 72px
    font-style: normal
    font-weight: 700
    line-height: normal
  &_desc
    color: #FFF
    text-align: center
    font-family: "Open Sauce One", sans-serif
    font-size: 16px
    font-style: normal
    font-weight: 500
    line-height: 20px
    width: 570px
@media(max-width: 720px)
  .main
    height: 650px
    &_title
      font-size: 32px
    &_desc
      font-size: 16px
      width: 290px