.quality {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
  background: #191919;
  gap: 100px;
}
.quality_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 230px;
}
.quality_item__title {
  text-align: center;
  color: #A9A9A9;
  font-family: "Open Sauce One", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 20px;
}
.quality_item__img {
  width: 120px;
}

@media (max-width: 1200px) {
  .quality {
    gap: 120px;
  }
  .quality_item__title {
    width: 120px;
  }
}
@media (max-width: 720px) {
  .quality {
    gap: 30px;
  }
  .quality_item__title {
    width: 88px;
    font-size: 12px;
    margin-bottom: 13px;
  }
  .quality_item__img {
    width: 80px;
  }
}

