.card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.card_hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 20px;
}
.card_item {
  width: 323px;
  height: 500px;
  position: relative;
}
.card_item:hover span {
  opacity: 1;
}
.card_wrap {
  display: flex;
  gap: 18px;
}
.card_wrap__item {
  position: relative;
  width: 443px;
  height: 241px;
}
.card_wrap__item:hover span {
  opacity: 1;
}

.card1 {
  width: 270px;
  height: 240px;
}

.card2 {
  width: 560px;
  height: 240px;
}

@media (max-width: 1820px) {
  .card {
    gap: 13px;
    align-items: flex-start;
  }
  .card_item {
    width: 212.046px;
    height: 365px;
  }
  .card_wrap {
    gap: 13px;
  }
  .card_wrap__item {
    width: 290.824px;
    height: 175.93px;
  }
  .card_wrap__img {
    height: 100%;
  }
  .card_img {
    height: 100%;
  }
  .card1 {
    width: 163.906px;
    height: 188.16px;
  }
  .card2 {
    width: 339.953px;
    height: 188.16px;
  }
}
@media (max-width: 1200px) {
  .card {
    gap: 13px;
    align-items: flex-start;
  }
  .card_item {
    width: 295.878px;
    height: 458.015px;
  }
  .card_wrap {
    gap: 16.5px;
  }
  .card_wrap__item {
    width: 405.802px;
    height: 220.763px;
  }
  .card_wrap__img {
    height: 100%;
  }
  .card_img {
    height: 100%;
  }
  .card1 {
    width: 228.706px;
    height: 203.294px;
  }
  .card2 {
    width: 474.353px;
    height: 203.294px;
  }
}
@media (max-width: 720px) {
  .card {
    margin-top: 40px;
    gap: 7px;
    align-items: center;
  }
  .card_hover {
    font-size: 8px;
  }
  .card_item {
    width: 131.502px;
    height: 203.562px;
  }
  .card_wrap {
    gap: 7px;
  }
  .card_wrap__item {
    width: 180.356px;
    height: 98.117px;
  }
  .card_wrap__img {
    height: 100%;
  }
  .card_img {
    height: 100%;
  }
  .card1 {
    width: 101.647px;
    height: 90.24px;
  }
  .card2 {
    width: 210.824px;
    height: 90.24px;
  }
}

