@import "../../../Constants/variables.module"

.featured
  padding-top: 80px
  padding-bottom: 80px
  background-color: #000


.titles
  @include title


.wrap
  margin-top: 30px
  display: flex
  align-items: flex-end
  gap: 20px
  &_mainImg
    width: 570px
    height: 550px
  &_left
    display: flex
    flex-direction: column
    gap: 50px
    align-items: flex-start


.title
  color: #FFF
  font-family: "Open Sauce One", sans-serif
  font-size: 36px
  font-style: normal
  font-weight: 500
  line-height: normal
.subtitle
  color: #A9A9A9
  font-family: "Open Sauce One", sans-serif
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: 175%
  position: relative
  &::after
    content: ''
    position: absolute
    left: 0
    bottom: -8px
    height: 2px
    width: 100%
    align-self: stretch
    border-radius: 12px
    background-color: #FECB00


.imagesWrap
  display: flex
  align-items: center
  gap: 15px
  &_img
    width: 270px
    height: 400px



@media (max-width: 1820px)
  .wrap
    gap: 17px
    &_mainImg
      width: 470px
      height: 431px

  .imagesWrap
    gap: 17px
    &_img
      width: 150px
      height: 218px


@media (max-width: 1200px)
  .wrap
    display: flex
    flex-direction: column
    align-items: center
    gap: 40px
    &_mainImg
      width: 600px
      height: 550px
    &_left
      display: flex
      flex-direction: column
      gap: 50px
      align-items: center

  .imagesWrap
    display: flex
    align-items: center
    justify-content: center
    flex-wrap: wrap
    gap: 15px
    &_img
      width: 272px
      height: 400px


@media (max-width: 720px)
  .titles
    &_title
      font-size: 24px

  .wrap
    display: flex
    flex-direction: column
    align-items: center
    gap: 40px
    &_mainImg
      width: 320px
      height: 294px
    &_left
      display: flex
      flex-direction: column
      gap: 50px
      align-items: center


  .title
    font-size: 22px
  .subtitle
    font-size: 14px

  .imagesWrap
    display: flex
    align-items: center
    justify-content: center
    flex-wrap: wrap
    column-gap: 12px
    row-gap: 20px
    &_img
      width: 154.326px
      height: 226.95px
