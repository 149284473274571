.form {
  display: flex;
  justify-content: center;
}
.form_bg {
  background-image: url("../../Assets/Images/LandingPage/FormBg.webp");
  background-size: cover;
  padding: 100px 0;
}
.form_first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #222;
  padding: 0 92px;
  gap: 10px;
  width: 620px;
}
.form_second {
  display: flex;
  padding: 52px 86px;
  justify-content: center;
  align-items: flex-start;
  width: 700px;
  background: #2F2F2F;
}
.form_wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  color: #FFF;
  font-family: "Open Sauce One", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.line {
  border-radius: var(--layout-radius-rounded-medium, 12px);
  background: #FECB00;
  width: 419.492px;
  height: 3px;
  flex-shrink: 0;
}

.desc {
  color: #A9A9A9;
  font-family: "Open Sauce One", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.input {
  color: #FFF !important;
  font-family: "Open Sauce One", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.input_bg {
  border-radius: var(--radius-md, 8px) !important;
  background: #4F4F4F !important;
  color: #FFF !important;
  font-family: "Open Sauce One", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dropdown {
  display: flex;
  flex-direction: column;
  color: #A9A9A9;
  border: none;
  font-family: "Open Sauce One", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.dropdown_label {
  color: #FFF;
  font-family: "Open Sauce One", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6px;
}
.dropdown_select {
  color: #FFF;
  background: #4F4F4F !important;
  border: 1px solid #A9A9A9;
}
.dropdown_bg {
  color: #A9A9A9;
  background: #4F4F4F !important;
}

.submit {
  border-radius: 12px;
  background: #FECB00;
  display: flex;
  width: 240px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.privacy {
  width: 90%;
  margin: 8px auto auto auto;
  color: #A9A9A9;
  text-align: center;
  font-family: "Open Sauce One", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

@media (max-width: 1200px) {
  .form {
    flex-direction: column;
    align-items: center;
  }
  .form_first {
    width: 720px;
    height: 300px;
  }
  .form_second {
    width: 720px;
  }
}
@media (max-width: 720px) {
  .form_bg {
    background-image: url("../../Assets/Images/LandingPage/FormBgMb.webp");
    padding: 50px 0;
  }
  .form_first {
    width: 90%;
    background: transparent;
    padding: 0;
    height: 170px;
  }
  .form_second {
    width: 90%;
    background: transparent;
    padding: 0;
  }
  .line {
    width: 100%;
  }
  .title {
    font-size: 24px;
  }
  .desc {
    font-size: 14px;
  }
}

