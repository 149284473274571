.card
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  gap: 20px
  &_hover
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(50, 50, 50, 0.70)
    color: white
    display: flex
    align-items: center
    justify-content: center
    opacity: 0
    transition: opacity 0.3s ease
    padding: 20px
  &_item
    width: 323px
    height: 500px
    position: relative
    &:hover
      span
        opacity: 1
  &_wrap
    display: flex
    gap: 18px
    &__item
      position: relative
      width: 443px
      height: 241px
      &:hover
        span
          opacity: 1
.card1
  width: 270px
  height: 240px
.card2
  width: 560px
  height: 240px
@media(max-width: 1820px)
  .card
    gap: 13px
    align-items: flex-start
    &_item
      width: 212.046px
      height: 365px
    &_wrap
      gap: 13px
      &__item
        width: 290.824px
        height: 175.93px
      &__img
        height: 100%
    &_img
      height: 100%
  .card1
    width: 163.906px
    height: 188.16px
  .card2
    width: 339.953px
    height: 188.16px
@media(max-width: 1200px)
  .card
    gap: 13px
    align-items: flex-start
    &_item
      width: 295.878px
      height: 458.015px
    &_wrap
      gap: 16.5px
      &__item
        width: 405.802px
        height: 220.763px
      &__img
        height: 100%
    &_img
      height: 100%
  .card1
    width: 228.706px
    height: 203.294px
  .card2
    width: 474.353px
    height: 203.294px
@media(max-width: 720px)
  .card
    margin-top: 40px
    gap: 7px
    align-items: center
    &_hover
      font-size: 8px
    &_item
      width: 131.502px
      height: 203.562px
    &_wrap
      gap: 7px
      &__item
        width: 180.356px
        height: 98.117px
      &__img
        height: 100%
    &_img
      height: 100%
  .card1
    width: 101.647px
    height: 90.24px
  .card2
    width: 210.824px
    height: 90.24px