.quality
  display: flex
  align-items: center
  justify-content: center
  padding: 60px
  background: #191919
  gap: 100px
  &_item
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 230px
    &__title
      text-align: center
      color: #A9A9A9
      font-family: "Open Sauce One", sans-serif
      font-size: 16px
      font-style: normal
      font-weight: 600
      margin-bottom: 20px
    &__img
      width: 120px
@media(max-width: 1200px)
  .quality
    gap: 120px
    &_item
      &__title
        width: 120px

@media(max-width: 720px)
  .quality
    gap: 30px
    &_item
      &__title
        width: 88px
        font-size: 12px
        margin-bottom: 13px
      &__img
        width: 80px
        
