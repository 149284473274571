.gallery
  padding: 100px 0
  background-image: url("../../../Assets/Images/LandingPage/Gallery/GalleryWeb.webp")
  background-size: cover
  &_wrap
    display: grid
    grid-template-columns: 588px auto
    grid-gap: 40px
  &_wrap1
    display: flex
    gap: 20px
    &__main
      width: 428px
      height: 428px!important
    &__second
      width: 137px
      height: 137px
    &__block
      display: flex
      flex-direction: column
      gap: 9px
  &_wrap2
    grid-column-start: 2
    grid-row-start: 1
    display: flex
    flex-direction: column
    &__title
      color: #FFF
      font-family: "Open Sauce One", sans-serif
      font-size: 36px
      font-style: normal
      font-weight: 700
      z-index: 10
      a
        color: #FFF
        font-family: "Open Sauce One", sans-serif
        font-style: normal
        font-weight: 700
        z-index: 10
    &__desc
      color: #A9A9A9
      font-family: "Open Sauce One", sans-serif
      font-size: 16px
      font-style: normal
      font-weight: 400
      line-height: 175%
    &__wrapper
      display: grid
      grid-template-columns: 387px 214px 387px
      grid-column-start: 2
      grid-row-start: 1
      align-items: flex-end

      gap: 11px
    &__item
      display: flex
      flex-direction: column
      gap: 11px
      &_img
        width: 214px
        height: 132px
    &__img1,&__img2
      width: 387px
      height: 277px
@media(max-width: 1820px)
  .gallery
    &_wrap2
      &__wrapper
        grid-row-start: 2
        grid-column-start: 1
        grid-column-end: 3
        grid-template-columns: 367px 364px 387px
        gap: 20px
      &__img1,&__img2
        object-fit: cover
        height: 470px
      &__item
        gap: 20px
        &_img
          width: 364px
          height: 225px
@media(max-width: 1200px)
  .gallery
    &_wrap
      grid-template-columns: auto
      grid-gap: 0
      row-gap: 44px
    &_wrap2
      grid-column-start: 1
      &__desc
        font-size: 14px
      &__wrapper
        grid-template-columns: 335px 364px
        grid-column-start: 1
        grid-row-start: 3
      &__img1
        width: 335px
      &__img2
        display: none
      &__item
        &_img
          width: 364px
    &_wrap1
      grid-row-start: 2
      &__main
        height: 523.742px
        width: 526px
      &__second
        width: 168.693px
        height: 167.987px
@media(max-width: 720px)
  .gallery
    padding: 50px 0
    &_wrap
      grid-template-columns: 320px
      justify-content: center
      grid-gap: 0
      row-gap: 20px
    &_wrap2
      margin: auto
      grid-column-start: 1
      &__title
        font-size: 24px
      &__desc
        line-height: 18px
      &__wrapper
        grid-template-columns: 320px
        grid-column-start: 1
        grid-row-start: 3
        margin: auto
      &__img1
        width: 320px
        height: 225px
      &__img2
        display: none
      &__item
        display: none
    &_wrap1
      flex-direction: column
      margin: auto
      &__block
        flex-direction: row
      &__main
        height: 320px
        width: 320px
      &__second
        width: 101px
        height: 101px
