.partners
  background: #111
  display: flex
  padding: 100px 0
.title
  color: #FFF
  font-family: "Open Sauce One", sans-serif
  font-size: 44px
  font-style: normal
  font-weight: 500
  line-height: normal
  margin: 0
  &_wrap
    display: flex
    gap: 32px
    align-items: flex-end
    margin-bottom: 32px
  &_line
    border-radius: var(--layout-radius-rounded-medium, 12px)
    background: #FECB00
    height: 2px
    flex: 1 0 0
.desc
  color: #FECB00
  text-align: justify
  font-family: "Open Sauce One", sans-serif
  font-size: 16px
  font-style: normal
  font-weight: 500
  margin: 0
  &_wrap
    display: flex
    padding: var(--spacing-2xl, 20px) var(--spacing-3xl, 24px)
    align-items: center
    gap: 12px
    border-radius: var(--layout-radius-rounded-medium, 12px)
    border: 1px solid rgba(254, 203, 0, 0.60)
    background: rgba(254, 203, 0, 0.02)
    margin-bottom: 32px
    width: 961px
  &_line
    border-radius: 33px
    background: #FECB00
    width: 4px
    height: 25px
.partners_wrap
  display: flex
  background: #FFF
  padding: 50px 25px
.main
  width: 450.878px
  height: 450.526px
.second
  width: 281px
  height: auto
  &_wrap
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
@media(max-width: 1820px)
  .title
    font-size: 40px
  .main
    width: 298.838px
    height: 298.604px
  .second
    width: 170px
    height: auto
@media(max-width: 1200px)
  .desc
    &_wrap
      width: 720px
  .main
    width: 180px
    height: 180px
  .second
    width: 120px
    height: auto
@media(max-width: 720px)
  .title
    font-size: 25px
    &_wrap
      gap: 10px
      margin-bottom: 20px
  .desc
    font-size: 14px
    &_wrap
      width: 90%
      margin: auto auto 32px auto
    &_line
      height: 72px
  .partners
    &_wrap
      flex-direction: column
      align-items: center

      