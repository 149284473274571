.titles
  display: flex
  align-items: flex-end
  gap: 32px
  &_line
    all: unset
    height: 2px
    width: 760px
    flex: 1 0 0
    opacity: 1
    border-radius: 12px
    background: #FECB00
    margin: 0
  &_title
    color: #FFF
    font-family: "Open Sauce One", sans-serif
    font-size: 44px
    font-style: normal
    font-weight: 500
    line-height: normal
    margin: 0
.numbers
  height: 344px
  background-image: url("../../../Assets/Images/RepairsPage/Numbers/NumbersBg.webp")
  background-size: cover
  background-position: center
  padding: 80px 0
  &_wrap
    display: flex
    gap: 200px
    justify-content: center
  &_item
    display: flex
    flex-direction: column
    align-items: center
    width: 380px
    margin-top: 34px
    &__number
      color: #FFF
      font-family: "Open Sauce One", sans-serif
      font-size: 44px
      font-style: normal
      font-weight: 500
      line-height: normal
      margin-bottom: 12px
    &__wrap
      display: flex
      gap: 8px
      align-items: center
    &__img
      width: 30px
      height: 30px
    &__title
      color: #FFF
      font-family: "Open Sauce One", sans-serif
      font-size: 20px
      font-style: normal
      font-weight: 400
      line-height: normal
      margin: 0
@media (max-width: 1820px)
  .numbers
    &_item
      &__number
        font-size: 40px
      &__title
        font-size: 18px
@media (max-width: 1200px)
  .titles
    &_title
      font-size: 40px
  .numbers
    height: 309px
    &_wrap
      gap: 40px
    &_item
      &__number
        font-size: 32px
      &__title
        font-size: 16px
@media (max-width: 720px)
  .titles
    &_title
      font-size: 22px
      margin: auto
    &_line
      display: none
  .numbers
    height: 424px
    padding: 40px 0
    &_wrap
      align-items: center
      flex-direction: column
      gap: 0
    &_item
      width: auto
      margin-top: 32px
      
