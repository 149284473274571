.services_block_web {
  padding-top: 40px;
  padding-bottom: 120px;
  background: #111;
}

.services_block_mobile {
  display: none;
}

.services_title_block {
  display: flex;
  align-items: flex-end;
  gap: 32px;
  margin-bottom: 80px;
}

.services_title {
  color: #FFF;
  font-family: Open Sauce One, sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.service_title_line {
  height: 2px;
  flex: 1 0 0;
  border-radius: 12px;
  background: #FECB00;
}

.services_btn {
  border-radius: 12px;
  background: #FECB00;
  color: #111;
  font-family: Open Sauce One, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.services_cards_block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.services_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.service_card_bg {
  background-image: url("../../../Assets/Images/LandingPage/Services/card4.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 415px;
  height: 530px;
  display: flex;
  align-items: flex-end;
}

.service_card_bg1 {
  background-image: url("../../../Assets/Images/LandingPage/Services/card1.webp");
}

.service_card_bg2 {
  background-image: url("../../../Assets/Images/LandingPage/Services/card2.webp");
}

.service_card_bg3 {
  background-image: url("../../../Assets/Images/LandingPage/Services/card3.webp");
}

.services_text_block {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-left: 40px;
  padding-bottom: 30px;
}

.services_card_text {
  color: #FECB00;
  font-family: Open Sauce One, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

@media (max-width: 1820px) {
  .service_card_bg {
    width: 270px;
    height: 420px;
  }
}
@media (max-width: 1200px) {
  .services_title {
    font-size: 40px;
  }
  .service_card_bg {
    width: 350px;
    height: 420px;
  }
  .services_cards_block {
    justify-content: center;
    gap: 20px;
    row-gap: 48px;
  }
}
@media (max-width: 720px) {
  .services_block_web {
    display: none;
  }
  .services_block_mobile {
    padding-top: 50px;
    padding-bottom: 50px;
    background: #111;
    display: flex;
  }
  .services_title_block_mobile {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    margin-bottom: 16px;
  }
  .services_title_mobile {
    color: #FFF;
    font-family: Open Sauce One, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
  .service_title_text_mobile {
    color: #A9A9A9;
    font-family: Open Sauce One, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
  }
  .service_title_line_mobile {
    height: 2px;
    flex: 1 0 0;
    border-radius: 12px;
    background: #FECB00;
  }
  .services_btn_block_mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 320px;
  }
  .services_btn_mobile {
    border-radius: 8px;
    background: rgba(254, 203, 0, 0.75);
    color: #111;
    font-family: Open Sauce One, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 6px 12px;
    height: 28px;
  }
  .services_cards_block_mobile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
  }
  .services_card_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .service_card_bg_mobile {
    background-image: url("../../../Assets/Images/LandingPage/Services/card4.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 350px;
    height: 240px;
    display: flex;
    align-items: flex-end;
  }
  .service_card_bg1 {
    background-image: url("../../../Assets/Images/LandingPage/Services/card1.webp");
  }
  .service_card_bg2 {
    background-image: url("../../../Assets/Images/LandingPage/Services/card2.webp");
  }
  .service_card_bg3 {
    background-image: url("../../../Assets/Images/LandingPage/Services/card3.webp");
  }
  .services_text_block_mobile {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .services_card_text_mobile {
    color: #FECB00;
    font-family: Open Sauce One, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }
}

