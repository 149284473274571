.vinyl {
  background: #191919;
  padding: 80px 0;
}
.vinyl_wrap {
  padding: 40px 0 0 0;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .vinyl_wrap {
    flex-direction: column;
    align-items: center;
  }
}

